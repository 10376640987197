import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import media from '../../utils/helpers/media';
import arrowRight from '../../assets/icons/arrow_right.svg';

const VacancySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 10px auto 70px;
  align-items: center;
  ${media.phone`
    padding: 35px;
    margin-bottom: 25px;
  ;`}
`;

const VacancyTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.25px;
  font-weight: 600;
  margin: 10px 0 40px;
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  ;`}
`;

const VacancyList = styled.ul`
  font-family: Avenir Next;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background: #fff;
  width: 698px;
  ${media.desktop`
    width: 100%;
  `}
`;

const VacancyItem = styled.li`
  border-bottom: 1px solid #eeeeee;
  min-height: 55px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:after {
    content: '';
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: url(${arrowRight}) no-repeat center center;
    cursor: pointer;
  }
`;

const VacancyLink = styled(Link)`
  font-family: inherit;
  color: inherit;
  text-decoration: none;
`;

const vacancyList = [
  {
    id: 1,
    url: '/vacancy',
    title: 'Директор по маркетингу'
  },
  {
    id: 2,
    url: '/vacancy',
    title: 'PR-менеджер'
  },
  {
    id: 3,
    url: '/vacancy',
    title: 'Инженер по автоматизации тестирования API'
  }
];

const VacancySection = () => {
  return (
    <VacancySectionContainer>
      <VacancyTitle>Станьте частью нашей команды</VacancyTitle>
      <VacancyList>
        {vacancyList.map(item => (
          <VacancyLink to={item.url} key={item.id}>
            <VacancyItem>{item.title}</VacancyItem>
          </VacancyLink>
        ))}
      </VacancyList>
    </VacancySectionContainer>
  );
};

export default VacancySection;
