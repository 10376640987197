import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';

const AdvantageItemContainer = styled.li`
  max-width: 490px;
  width: 48%;
  min-height: 258px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(201, 188, 224, 0.25);
  border-radius: 25px;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
  box-sizing: border-box;
  ${media.phone`
    width: 100%;
    max-width: 344px;
    min-height: 193px;
    padding: 20px;
  ;`}
`;

const AdvantageItemTitle = styled.h3`
  font-family: Gilroy;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 9px;
  margin-top: 18px;
  text-align: center;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    margin: 5px 0 10px;
  ;`}
`;

const AdvantageItemImage = styled.img`

`

const AdvantageItemDescription = styled.p`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
  ;`}
`;

const AdvantageItem = ({ item: { icon, title, description } }) => {
  const small = useMedia('(max-width: 576px)');

  const iconSize = small ? '30' : '50';

  return (
    <AdvantageItemContainer>
      <AdvantageItemImage src={icon} width={iconSize} height={iconSize} alt={title} />
      <AdvantageItemTitle>{title}</AdvantageItemTitle>
      <AdvantageItemDescription>{description}</AdvantageItemDescription>
    </AdvantageItemContainer>
  )
}

AdvantageItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
};

export default AdvantageItem;