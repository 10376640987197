import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';
import TeamItem from './TeamItem';
import useMedia from '../../hooks/useMedia';
import media from '../../utils/helpers/media';
import vars from '../../utils/styles/vars';
import arrowRight from '../../assets/icons/arrow_right.svg';
import arrowLeft from '../../assets/icons/arrow_left.svg';
import Team1 from '../../assets/images/team/team1.jpg';
import Team2 from '../../assets/images/team/team2.jpg';
import Team3 from '../../assets/images/team/team3.jpg';
import Team4 from '../../assets/images/team/team4.jpg';
import Team5 from '../../assets/images/team/team5.jpg';
import Team6 from '../../assets/images/team/team6.jpg';
import Team7 from '../../assets/images/team/team7.jpg';
import Team8 from '../../assets/images/team/team8.jpg';

const TeamSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 50px auto 50px;
  align-items: center;
  ${media.desktop`
    margin: 40px auto 40px;
  `}
`;

const TeamTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.25px;
  font-weight: 600;
  margin-bottom: 42px;
  ${media.desktop`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  `}
`;

const TeamCarouselContainer = styled.div`
  width: 1020px;
  padding: 0 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TeamList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TeamAllButton = styled.button`
  border: none;
  background: transparent;
  color: ${vars.colors.primaryColor};
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  width: 257px;
  padding: 12px 0;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
`;

// TODO: move slider styles to separate file
const ExamplesArrow = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 120px;
  z-index: 1;
  cursor: pointer;
  ${media.desktop`
    top: 110px;
  `}
`;

const ExamplesArrowLeft = styled(ExamplesArrow)`
  background: url(${arrowLeft}) no-repeat center center;
  left: 0;
`;

const ExamplesArrowRight = styled(ExamplesArrow)`
  background: url(${arrowRight}) no-repeat center center;
  right: 0;
`;

const employeeList = [
  {
    id: 1,
    name: 'Питер Хаген',
    image: Team1,
    position: 'Advisor'
  },
  {
    id: 2,
    name: 'Виктор Лавренко',
    image: Team2,
    position: 'CEO & Founder'
  },
  {
    id: 3,
    name: 'Лена Колесник',
    image: Team3,
    position: 'Chief Accountant'
  },
  {
    id: 4,
    name: 'Тамара Зуйкова',
    image: Team4,
    position: 'Head of Engineering'
  },
  {
    id: 5,
    name: 'Аня Павлыш',
    image: Team5,
    position: 'Head of Customer Service'
  },
  {
    id: 6,
    name: 'Лена Григорьева',
    image: Team6,
    position: 'Graphic Designer'
  },
  {
    id: 7,
    name: 'Аня Мироненко',
    image: Team7,
    position: 'Behavioral Product Manager'
  },
  {
    id: 8,
    name: 'Паша Конев',
    image: Team8,
    position: 'Head of Insurance'
  }
];

const TeamSection = () => {
  const [activeSlide, setActiveSlide] = useState(1);
  const [showAllDisabled, setShowAllDisabled] = useState(0);
  const [employeeListPreview, setEmployeeListPreview] = useState(
    employeeList.slice(0, 4)
  );

  const small = useMedia('(max-width: 992px)');
  const slidesPerPage = 4;

  const prevSlide = () => {
    setActiveSlide(Math.max(activeSlide - 1, 1));
  };

  const nextSlide = () => {
    if (activeSlide <= employeeList.length - slidesPerPage) {
      setActiveSlide(Math.min(activeSlide + 1, employeeList.length));
    }
  };

  const changeSlide = value => {
    setActiveSlide(
      Math.min(value + 1, employeeList.length - slidesPerPage + 1)
    );
  };

  const showAll = () => {
    setEmployeeListPreview(employeeList);
    setShowAllDisabled(1);
  };

  return (
    <TeamSectionContainer>
      <TeamTitle>Наша команда</TeamTitle>
      {small ? (
        <TeamContainer>
          <TeamList>
            {employeeListPreview.map(item => (
              <TeamItem key={item.id} item={item} />
            ))}
          </TeamList>
          {!showAllDisabled && (
            <TeamAllButton onClick={showAll}>Показать всех</TeamAllButton>
          )}
        </TeamContainer>
      ) : (
        <TeamCarouselContainer>
          <Carousel
            arrowLeft={
              <ExamplesArrowLeft onClick={prevSlide} onKeyDown={prevSlide} />
            }
            arrowRight={
              <ExamplesArrowRight onClick={nextSlide} onKeyDown={nextSlide} />
            }
            keepDirectionWhenDragging
            slidesPerPage={slidesPerPage}
            slidesPerScroll={1}
            onChange={changeSlide}
            value={activeSlide - 1}
          >
            {employeeList.map(item => (
              <TeamItem key={item.id} item={item} />
            ))}
          </Carousel>
        </TeamCarouselContainer>
      )}
    </TeamSectionContainer>
  );
};

export default TeamSection;
