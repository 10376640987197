import React from 'react';
import { YMaps, Map, ZoomControl, Placemark } from 'react-yandex-maps';
import styled from 'styled-components';
import media from '../../utils/helpers/media';
import vars from '../../utils/styles/vars';
import useMedia from '../../hooks/useMedia';

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
`;

const ContactsTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.25px;
  font-weight: 600;
  margin: 30px 0 37px;
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
  `}
`;

const ContactsAddress = styled.div`
  display: flex;
  width: 100%;
  min-height: 460px;
  ${media.phone`
    flex-direction: column;
  `}
`;

const ContactsAddressColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 43px 69px 24px;
  width: 733px;
  box-sizing: border-box;
  ${media.phone`
    text-align: center;
    align-items: center;
    width: 100%;
    padding: 16px 20px;
  `}
`;

const ContactsMapColumn = styled.div`
  width: 709px;
  ${media.phone`
    width: 100%;
  `}
`;

const ContactsAddressTitle = styled.h3`
  font-family: Avenir Next;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;
  font-weight: 500;
  margin-bottom: 20px;
  ${media.phone`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    margin-bottom: 13px;
    font-weight: 600;
  `}
`;

const ContactsAddressDescription = styled.p`
  font-family: Avenir Next;
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 10px;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: 16px;
    text-align: center;
  `}
`;

const ContactsAddressText = styled.p`
  font-family: Avenir Next;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
  max-width: 354px;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    max-width: 308px;
  `}
`;

const ContactsAddressLink = styled.a`
  font-family: Avenir Next;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
  max-width: 354px;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    color: ${vars.colors.primaryColor};
    text-align: center;
  `}
`;

const ContactsAddressOffice = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const ContactsAddressEmail = styled.div`
  display: flex;
  flex-direction: column;
`;

const mainEmail = 'victor@mango.rocks';
const pressEmail = 'press@mango.rocks';
const ContactsSection = () => {
  const small = useMedia('(max-width: 576px)');

  const styleObj = small
    ? { width: '100%', height: 217 }
    : { width: 709, height: 460 };

  const geoCenter = [55.74644606901241, 37.62901699999993];

  return (
    <ContactsContainer>
      <ContactsTitle>Как нас найти</ContactsTitle>
      <ContactsAddress>
        <ContactsMapColumn>
          <YMaps>
            <Map
              style={styleObj}
              defaultState={{
                center: geoCenter,
                zoom: 14,
                controls: [],
              }}
            >
              <ZoomControl options={{ float: 'right' }} />
              <Placemark geometry={geoCenter} />
            </Map>
          </YMaps>
        </ContactsMapColumn>
        <ContactsAddressColumn>
          <ContactsAddressOffice>
            <ContactsAddressTitle>Приходите к нам</ContactsAddressTitle>
            <ContactsAddressDescription>
              Наш офис в коворкинге «Рабочая станция»
            </ContactsAddressDescription>
            <ContactsAddressText>
              Москва, Садовническая набережная, 9 Ближайшие станции метро:
              Новокузнецкая
            </ContactsAddressText>
          </ContactsAddressOffice>
          <ContactsAddressEmail>
            <ContactsAddressTitle>Пишите нам</ContactsAddressTitle>
            <ContactsAddressDescription>
              По важным вопросам:{' '}
            </ContactsAddressDescription>
            <ContactsAddressLink href={`mailto:${mainEmail}`}>
              {mainEmail}
            </ContactsAddressLink>
            <ContactsAddressDescription>
              Адрес для прессы:{' '}
            </ContactsAddressDescription>
            <ContactsAddressLink href={`mailto:${pressEmail}`}>
              {pressEmail}
            </ContactsAddressLink>
          </ContactsAddressEmail>
        </ContactsAddressColumn>
      </ContactsAddress>
    </ContactsContainer>
  );
};

export default ContactsSection;
