import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../utils/common/Button';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  animation-duration: 0.75s;
  animation-name: fadeIn;
  margin: ${props => (props.landing ? 0 : '40px 0 100px 0')};

  ${media.phone`
    flex-direction: row-reverse;
    width: 100%;
  ;`}
`;

const StyledButton = styled(Button)`
  z-index: 50;
  font-weight: 500;
  height: 56px;
  width: 240px;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;

  ${media.phone`
    font-size: ${props => (props.mobile ? '18px' : '9px')};
    line-height: 28px;
    height: ${props => (props.mobile ? '56px' : 'inherit')};
    width: ${props => (props.mobile ? '264px' : 'inherit')};
  ;`}
`;

const MobileButton = styled(Button)`
  z-index: 50;
  height: 56px;
  width: 208px;
  transform: translate3d(0, 0, 0);
  letter-spacing: 1.2px;
  font-weight: 500;
  line-height: 28px;
  padding-left: 18px;
  padding-right: 18px;
`;

const HomeAction = ({ landing }) => {
  const handleSubmit = () => {
    window.location.assign(
      `https://customer.service.mango.rocks/offer/address`
    );
  };

  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');
  return (
    <ActionWrapper landing={landing}>
      {large && <StyledButton text="рассчитать цену" onClick={handleSubmit} />}
      {small && <MobileButton text="рассчитать цену" onClick={handleSubmit} />}
    </ActionWrapper>
  );
};

HomeAction.propTypes = {
  landing: PropTypes.bool.isRequired
};

export default HomeAction;
