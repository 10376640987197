import React from 'react';
import styled from 'styled-components';
import media from '../../utils/helpers/media';
import AdvantageItem from './AdvantageItem';
import AlfaFinancingIcon from '../../assets/icons/alfa-financing.svg';
import BestServiceIcon from '../../assets/icons/best-service.svg';
import ClarityIcon from '../../assets/icons/clarity.svg';
import LecinseIcon from '../../assets/icons/lecinse.svg';
import BorderLine from '../../assets/icons/landing-hero-bottom-line.svg';
import BorderImage from '../../assets/images/home.svg';

const AdvantageContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 48px 212px 0;
  ${media.desktop`
    flex-direction: column;
    align-items: center;
    padding: 40px 15px 36px;
    background: url(${BorderLine}) no-repeat bottom center;
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      width: 40px;
      height: 38px;
      position: absolute;
      bottom: 10px;
      right: 40px;
      background: url(${BorderImage});
    }
;`}
`;

const AdvantageTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.25px;
  margin-bottom: 70px;
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 26px;
  `}
`;

const AdvantageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  ${media.phone`
    flex-direction: column;
    align-items: center;
  `}
`;

const itemList = [
  {
    id: 1,
    icon: AlfaFinancingIcon,
    title: 'Нас финансирует «Альфа-групп»',
    description: 'Нас финансирует «Альфа-групп», и мы гордимся этим партнерством. На данный момент сумма полученных нами инвестиций составляет 500 000 000 рублей'
  },
  {
    id: 2,
    icon: BestServiceIcon,
    title: 'Лучший страховой сервис',
    description: 'Мы отказались от офисов и бюрократии, чтобы дать вам доступный сервис, с которым все можно решить через ваш смартфон - от покупки страховки до получения выплаты.'
  },
  {
    id: 3,
    icon: ClarityIcon,
    title: 'Прозрачность',
    description: 'Между нами и нашими клиентами нет агентов и посредников, поэтому оставляем себе всего 30% страховых взносов, а остальное возвращаем вам в конце года.'
  },
  {
    id: 4,
    icon: LecinseIcon,
    title: 'Лицензия',
    description: 'Мы единственный российский страховой стартап, который получил собственную страховую лицензию — чтобы сделать удобным и выгодным каждый шаг нашего взаимодействия с клиентами.'
  }
]

const AdvantageSection = () => {
  return (
    <AdvantageContainer>
      <AdvantageTitle>Почему нам можно верить</AdvantageTitle>
      <AdvantageList>
        {itemList.map(item => (
          <AdvantageItem key={item.id} item={item} />
        ))}
      </AdvantageList>
    </AdvantageContainer>
  )
}

export default AdvantageSection;