import React from 'react';
import styled from 'styled-components';
import media from '../../utils/helpers/media';
import vars from '../../utils/styles/vars';
import bottomLine from '../../assets/icons/landing-hero-bottom-line.svg';
import HeroMango from '../../assets/images/hero-mango.png';

const HeroSectionContainer = styled.div`
  display: flex;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: 97px 121px 80px;
  overflow: hidden;
  &:after {
    content: '';
    display: inline-block;
    height: 34px;
    position: absolute;
    left: 14.5%;
    right: 14.5%;
    bottom: 0;
    background: url(${bottomLine}) no-repeat right top;
  }
  &:before {
    content: '';
    background-image: ${vars.heroBackground};
    background-size: cover;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  ${media.desktop`
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 23px 34px 35px 34px;
    &:before {
      display: none;
    }
    &:after {
      left: 0;
      right: -20px;
    }
  ;`}
`;

const HeroSectionText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-top: 52px;
  ${media.phone`
    margin-top: 33px;
    margin-left: 0;
  ;`}
`;

const HeroSectionTitle = styled.h1`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.25px;
  margin-bottom: 25px;
  font-weight: bold;
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 22px;
    text-align: center;
  ;`}
`;

const HeroSectionDescription = styled.p`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;
  max-width: 630px;
  font-weight: 500;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: normal;
  ;`}
`;

const HeroImage = styled.img`
  width: 464px;
  max-width: 100%;
  height: 334px;
  flex-shrink: 0;
  flex-grow: 0;
  ${media.phone`
    width: 100%;
    max-width: 288px;
    max-height: 208px;
    height: auto;
  ;`}
`;

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <HeroImage src={HeroMango} alt="about image" />
      <HeroSectionText>
        <HeroSectionTitle>Мы — молодая страховая компания.</HeroSectionTitle>
        <HeroSectionDescription>
          Наша цель — сделать страхование имущества рабочим продуктом, которому
          можно доверять.
        </HeroSectionDescription>
      </HeroSectionText>
    </HeroSectionContainer>
  );
};

export default HeroSection;
