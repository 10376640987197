import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import vars from '../../utils/styles/vars';
import media from '../../utils/helpers/media';

const TeamItemContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid rgba(121, 76, 250, 0.2);
  width: 214px;
  height: 214px;
  padding: 14px;
  font-family: Avenir Next;
  cursor: default;
  ${media.desktop`
    border: none;
    width: 170px;
    min-height: 150px;
    height: auto;
    padding: 0;
    margin: 10px 4px 32px;
  `}
`;

const TeamItemName = styled.p`
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  font-weight: 600;
  margin: 13px 0 0;
  ${media.desktop`
    margin-top: 10px;
  `}
`;

const TeamItemPosition = styled.p`
  color: ${vars.colors.mainGrey};
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  margin: 0;
`;

const TeamItem = ({ item: { image, name, position } }) => {
  return (
    <TeamItemContainer>
      <img width="128" height="128" src={image} alt={name} />
      <TeamItemName>{name}</TeamItemName>
      <TeamItemPosition>{position}</TeamItemPosition>
    </TeamItemContainer>
  );
};

TeamItem.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired
  }).isRequired
};

export default TeamItem;
