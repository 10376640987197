import React from 'react';
import styled from 'styled-components';
import media from '../../utils/helpers/media';
import HomeAction from '../Home/HomeAction';
import backgroundRight from '../../assets/images/city-background-right.svg';
import backgroundLeft from '../../assets/images/city-background-left.svg';

const ReasonSectionContainer = styled.div`
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(237, 239, 255, 0.37) 100%);
  ${media.phone`
    background: #fff;
  `}
`;

const ReasonSectionText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  background-image: url(${backgroundRight}), url(${backgroundLeft});
  background-repeat: no-repeat;
  background-position: right bottom, left bottom;
  padding: 74px 0 81px;
  ${media.phone`
    padding: 36px 32px 110px;
    background-size: 123px 72px;
    text-align: center;
  `}
`;

const ReasonTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.25px;
  margin: 0 0 38px;
  font-weight: 600;
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 26px;
  `}
`;

const ReasonFact = styled.p`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;
  margin: 0 0 15px;
  font-weight: 500;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
  `}
`;

const ReasonText = styled.p`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  white-space: pre-line;
  max-width: 804px;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
  `}
`;

const ReasonGoal = styled.p`
  margin: 3px 0 60px;
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    font-weight: 600;
    margin: 28px 0 11px;
    max-width: 248px;
  `}
`;

const ReasonSlogan = styled.p`
  font-family: Gilroy;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 70px;
  font-weight: 600;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    margin-bottom: 30px;
  `}
`;

const ReasonSection = () => {
  return (
    <ReasonSectionContainer>
      <ReasonSectionText>
        <ReasonTitle>Зачем мы это делаем</ReasonTitle>
        <ReasonFact>90% имущества в России не застраховано</ReasonFact>
        <ReasonText>Люди привыкли пускать такие вещи на самотек, надеясь на традиционное авось, при этом стоимость страховки жилья на столько мала, что большинство людей могут легко приобрести полис, а ущерб нанесенный в случае страхового случая равен стоимости нового ремонта.</ReasonText>
        <ReasonGoal>Мы хотим изменить эту ситуацию.</ReasonGoal>
        <ReasonSlogan>Застрахуйте квартиру и живите спокойно!</ReasonSlogan>
        <HomeAction mobileButton landing />
      </ReasonSectionText>
    </ReasonSectionContainer>
  )
}

export default ReasonSection;