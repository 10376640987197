import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroSection from '../components/About/HeroSection';
import AdvantageSection from '../components/About/AdvantageSection';
import ReasonSection from '../components/About/ReasonSection';
import TeamSection from '../components/About/TeamSection';
import VacancySection from '../components/About/VacancySection';
import ContactsSection from '../components/About/ContactsSection';

const IndexPage = () => (
  <Layout>
    <SEO title="О компании" />
    <HeroSection />
    <AdvantageSection />
    <ReasonSection />
    <TeamSection />
    <VacancySection />
    <ContactsSection />
  </Layout>
);

export default IndexPage;
